export enum CustomerType {
  ALL = 'ALL',
  ASSIGN = 'ASSIGN',
}

export enum PayeeAppType {
  FIXED = 'FIXED',
  POLLING = 'POLLING',
}

export enum SceneType {
  ALL = 'ALL',
  ASSIGN = 'ASSIGN',
}

// ------------------------------
export const FormStatusText = {
  WAIT_AUDIT: '待审核',
  APPROVED: '已通过',
  REJECTED: '不通过',
  GRANTED: '已发放',
};
export enum FormStatus {
  WAIT_AUDIT = 'WAIT_AUDIT',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  GRANTED = 'GRANTED',
}

export enum FormSwitchStatus {
  ENABLE = '1',
  DISABLE = '0',
}

export const FormTypeText = {
  '0': '优惠申请表样式',
};

export const FormGenerateStatusText = {
  GENERATING: '生成中',
  GENERATED: '已完成',
};
export enum FormGenerateStatus {
  GENERATING = 'GENERATING',
  GENERATED = 'GENERATED',
}
export enum TabName {
  ACCOUNT = 'account',
  COMPANY = 'company',
  ROUND = 'round',
  WARNING = 'warning'
}

export const DOC_HOST = 'https://ka-img-dev.billbear.cn/';
