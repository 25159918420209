<template>
  <main-content>
    <el-tabs v-model="activeName" @tab-click="handleTabClick">
      <el-tab-pane lazy label="账户管理" :name="TabName.ACCOUNT">
        <account ref="account" v-if="activeName === TabName.ACCOUNT" />
      </el-tab-pane>
      <el-tab-pane lazy label="公司管理" :name="TabName.COMPANY">
        <company ref="company" v-if="activeName === TabName.COMPANY" />
      </el-tab-pane>
      <el-tab-pane lazy label="轮询规则" :name="TabName.ROUND">
        <round ref="round" v-if="activeName === TabName.ROUND" />
      </el-tab-pane>
      <el-tab-pane lazy label="预警设置" :name="TabName.WARNING">
        <warning ref="warning" v-if="activeName === TabName.WARNING" />
      </el-tab-pane>
    </el-tabs>
  </main-content>
</template>
<script>
import { TabName } from '@/constants/pay';
import MainContent from '@/components/MainContent.vue';

export default {
  name: 'PayAccount',
  components: {
    MainContent,
    Account: () => import('../components/Account.vue'),
    Company: () => import('../components/Company.vue'),
    Round: () => import('../components/Round.vue'),
    Warning: () => import('../components/Warning.vue')
  },

  data() {
    return {
      activeName: TabName.ACCOUNT,
      TabName,
    };
  },
  created() {
    if (this.$route.hash) {
      this.activeName = this.$route.hash.substring(1);
    }
  },

  methods: {
    handleTabClick(tab) {
      if (this.$route.hash !== '#' + tab.name) {
        this.$router.replace({
          hash: tab.name,
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.topbar {
  margin-bottom: 20px;
  span {
    font-size: 14px;
  }
}
</style>
